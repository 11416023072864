import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Footer = ({ display }) => {
  return (
    <footer className={`${display}`}>
      <div className="flex items-center justify-center gap-1 py-1">
        <p className="highlight text-base my-auto">Surf & WebDesign </p>
        <a href="https://funksion.site">
          <StaticImage
            height={16}
            src="../images/logoF.png"
            alt="funksion logo"
            className="my-auto mt-1"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
