import React, { useState } from "react";
import { Link } from "gatsby";

import { ButtonMenu } from "../components/Buttons";

const Navlink = ({ name, to }) => {
  return (
    <Link
      to={to}
      activeClassName="border border-main  rounded-full w-32 h-8 pt-1 text-center"
      className={`whitespace-nowrap px-2 lg:px-0 lg:text-center transition ease-in-out delay-50 lg:hover:-translate-y-0.5 lg:hover:scale-105 hover:font-bold duration-300`}
    >
      {name}
    </Link>
  );
};

const Navbar = () => {
  const [display, setDisplay] = useState("hidden");
  return (
    <>
      <div
        onClick={() =>
          display === "hidden" ? setDisplay("block") : setDisplay("hidden")
        }
      >
        <ButtonMenu display={display} />
      </div>
      <div
        className={`${display} lg:block lg:text-sm xl:text-base z-40 font-semi flex flex-col absolute top-2 right-2 lg:relative lg:z-50 lg:-top-1 lg:left-0 lg:right-0 bg-primary lg:bg-opacity-60 shadow-md  w-80  gap-4 p-4 lg:px-4 lg:w-screen lg:pt-4 lg:shadow-none `}
      >
        <div className="z-50 pt-8 pb-2 ml-2 lg:pt-4 flex flex-col lg:pr-4 lg:items-center gap-5 lg:flex-row lg:justify-between">
          <Navlink name="Bienvenidos" to="/" />
          <Navlink name="Cabañas Vero" to="/card" />
          <Navlink name="Restaurante" to="/restaurant" />
          <Navlink name="Actividades" to="/activities" />
          <Navlink name="Surf en Nexpa" to="/activities/#surf" />
          <Navlink name="Donde estamos" to="/contact" />
        </div>
      </div>
      <div className="h-16 mb-1 lg:mb-0 z-0 lg:h-0"></div>
    </>
  );
};

export default Navbar;
