import React from "react";

export const ButtonMenu = ({ display }) => {
  return (
    <div className="relative left-0 right-0 lg:hidden">
      {display === "block" && (
        <div>
          <svg
            className="absolute top-2 right-2 z-50"
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 17L27.125 27.125M27.125 17L17 27.125L27.125 17Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.0625 36.125C29.829 36.125 36.125 29.829 36.125 22.0625C36.125 14.296 29.829 8 22.0625 8C14.296 8 8 14.296 8 22.0625C8 29.829 14.296 36.125 22.0625 36.125Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      {display === "hidden" && (
        <div className="lg:hidden">
          <svg
            className="absolute top-4 right-4 z-50"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.125 6.0625H20.125M4.125 12.0625H20.125M4.125 18.0625H20.125"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export const ButtonScroll = () => {
  return (
    <div className="flex gap-2 items-center p-4">
      <button aria-label="previous">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12" r="12" fill="#000" />
          <path
            d="M14.5302 7.22704C14.6708 7.36768 14.7498 7.55841 14.7498 7.75728C14.7498 7.95616 14.6708 8.14689 14.5302 8.28753L10.8177 12L14.5302 15.7125C14.6668 15.854 14.7424 16.0434 14.7407 16.2401C14.739 16.4367 14.6601 16.6248 14.521 16.7639C14.382 16.9029 14.1939 16.9818 13.9972 16.9835C13.8006 16.9852 13.6111 16.9096 13.4697 16.773L9.22691 12.5303C9.08631 12.3896 9.00732 12.1989 9.00732 12C9.00732 11.8012 9.08631 11.6104 9.22691 11.4698L13.4697 7.22704C13.6103 7.08643 13.801 7.00745 13.9999 7.00745C14.1988 7.00745 14.3895 7.08643 14.5302 7.22704V7.22704Z"
            fill="#FFF"
          />
        </svg>
      </button>
      <button aria-label="next">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12" r="12" fill="#000" />
          <path
            d="M10.4698 7.22704C10.3292 7.36768 10.2502 7.55841 10.2502 7.75728C10.2502 7.95616 10.3292 8.14689 10.4698 8.28753L14.1823 12L10.4698 15.7125C10.3332 15.854 10.2576 16.0434 10.2593 16.2401C10.261 16.4367 10.3399 16.6248 10.479 16.7639C10.618 16.9029 10.8061 16.9818 11.0028 16.9835C11.1994 16.9852 11.3889 16.9096 11.5303 16.773L15.7731 12.5303C15.9137 12.3896 15.9927 12.1989 15.9927 12C15.9927 11.8012 15.9137 11.6104 15.7731 11.4698L11.5303 7.22704C11.3897 7.08643 11.199 7.00745 11.0001 7.00745C10.8012 7.00745 10.6105 7.08643 10.4698 7.22704V7.22704Z"
            fill="#FFF"
          />
        </svg>
      </button>
    </div>
  );
};
